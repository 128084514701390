import '../scss/footer.scss';
import { Link } from 'react-router-dom';
import ScrollToTop from "react-scroll-to-top";

function FooterPage() {
  return (
        <div className="footer">
          <section>
          <div className="container">
            <div className="row">
          
              <div className="col-lg-4 col-md-6" style={{marginTop:'10px'}}>
                <h2><b><span className="fLogoTitle">Sruti Harihara Subramanian</span> </b> </h2>
                <p className="fSubText">
                Sruti Harihara Subramanian is a director.
                </p>
                <p> <a
          href="#"
          target="_blank"
          rel="noopener noreferrer" className="socialText"
        >
        <img src="./assets/icon-image/facebkicon.png" alt="FaceBook" className='infoSocialIcon' style={{margin:'10px'}}/></a> 
                 <a
          href="#"
          target="_blank"
          rel="noopener noreferrer"  className="socialText"
        >
        <img src="./assets/icon-image/youtube.png" alt="youtube" className='infoSocialIcon' style={{margin:'10px'}}/></a>
                 
                  <a
          href="#"
          target="_blank"
          rel="noopener noreferrer"  className="socialText"
        >
        <img src="./assets/icon-image/linkedin.png" alt="linkedin" className='infoSocialIcon' style={{margin:'10px'}}/></a> </p>
              </div>
              <div className="col-lg-4 col-md-6" style={{marginTop:'10px'}}>
              <h3 className="fLogoTitle"></h3>
                <div className="fSubText">
                
                 
                </div>
              </div>
              <div className="col-lg-4 col-md-6" style={{marginTop:'10px'}}>
                <h3 className="fLogoTitle"></h3>
               
              </div>
            </div>
          </div>
          </section>

        <section className="copyright" style={{backgroundColor : '#fff' }}>
          <div className="container footerText">
            <div className="row align-items-center">
              <div className="col-md-12">
                <div className="copyright-info text-center text-md-center">
                  <span>Copyright &copy; 
                     ratchu{ new Date().getFullYear() }
                    ,  <img src="./assets/sign.png" alt="Ratchus" className='myIcon'/>  Powered by Ratchus</span>
                </div>
              </div>
            </div>
          </div>
        </section>
        <ScrollToTop smooth style={{backgroundColor:'lightblue', color:'blue'}}/>
        </div>
  )
}

export default FooterPage