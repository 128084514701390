
export const isAuthenticated = ()=>{
    const current = new Date();
   // const exDate = `${current.getDate()}/${current.getMonth()+1}/${current.getFullYear()}`;
    const edate = `${current.getDate()}`;
   // const month = `${current.getMonth()}`;
   // const year = `${current.getFullYear()}`;
    //const eDate = `${current.getDate()+2}/${current.getMonth()+1}/${current.getFullYear()}`;
    return edate?true:false;
}

export const bankName=[
    {
      "id":101,
      "label":"Bank Name",
      "name":"INDIAN BANK",
    }
];

export const bankDetails=[
    {
      "id":101,
      "label":"Account Name",
      "name":"MAREESWARAN. K",
    },
    {
        "id":102,
        "label":"Account No",
        "name":"6048925665",
      },
      {
        "id":103,
        "label":"IFSC Code",
        "name":"IDIB000T148",
      },
      {
        "id":104,
        "label":"MICR Code",
        "name":"623019006",
      },
      {
        "id":105,
        "label":"Account Type",
        "name":"SAVING",
      },
      {
        "id":105,
        "label":"Branch Address",
        "name":"THANGACHIMADAM",
      },
      {
        "id":105,
        "label":"Contact Number",
        "name":"9840631753",
      },
];