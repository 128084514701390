import '../scss/about.scss';
import { Link } from 'react-router-dom';
import ImageSlider from '../ImageSlider';
import Slider from 'react-slick';


function NotesPage() {

  
  const slides=[
    {url:'assets/slider-pages/img1.jpg', head:'',title:'',subTitle:''},
    {url:'assets/slider-pages/img2.jpg', head:'',title:'',subTitle:''},
    {url:'assets/slider-pages/img3.jpg', head:'',title:'',subTitle:''},
    {url:'assets/slider-pages/img4.jpg', head:'',title:'',subTitle:''}
  ];

  let mainSettings = {
    infinite: true,
    autoplay:true,
    speed: 1000,
    arrows: false,
    slidesToShow: 1,
    slidesToScroll: 1,

    responsive: [
      {
        breakpoint: 960,
        settings: {
          slidesToShow: 1,
          slidesToScroll: 1,
          arrows: false,
        },
      },
      {
        breakpoint: 480,
        settings: {
          slidesToShow: 1,
          slidesToScroll: 1,
          arrows: false,
        },
      },
    ],
  };

  const mainSlides=[
    {id:1,url:'assets/slider-main/1.jpg', head:'',title:'',subTitle:''},
  ];

const containerStyles={

  margin:'0 auto',
  objectFit: 'cover'
}

  return (
    <div  className="body-inner" id="about">
      <section className="banner-carousel banner-carousel-1 mb-0">
      <div className='aboutHomeText'>
                <h1>Director’s note</h1>
                <Link to='/' className='homeTextLink'>Home</Link> | Director’s note
      </div>
       <div className='mainContainerStyles'>
            <Slider {...mainSettings}>
        {mainSlides.map((image) => (
                        <img loading="lazy" key={image.id} className='aboutImgContainer' src={image.url} alt='Ratchu Software' />
                ))}
        </Slider>
          </div>
      </section>
  <section id="main-container" className="infoContainer">
  <div className="container aboutSecBox">
  <div className="row text-center">
        <div className="col-lg-12">
          
        </div>
    </div>
    <div className="row">
        <div className="col-lg-6">
        <h2 className="secMidTitle"></h2>
          <p className='aboutBoxText'>  </p>
         
        </div>
        <div className="col-lg-6 mt-5 mt-lg-0">
          <div style={containerStyles} className="aboutImg">
            
          </div>    
        </div>
    </div>
  </div>
</section>

      
<section id="info-container" className="infoContainer">
  <div className="container aboutSecBox">
    <div className="row">
    <div className="col-lg-4 col-md-6 mb-5 ">
    <div className='aboutMidBoxText'>
      <h3 className='boxTitle'>Director's Notes</h3>
    <ul >
             <li></li>
              <li></li>
              <li></li>
              <li></li>
              <li></li>
              <li></li>
            
             </ul>
          </div>  
        </div>
        <div className="col-lg-8 col-md-6 mb-5">
        <h3 className="secTitle"></h3>
        <p className="aboutBoxText"><b></b>  </p>
        <p className='aboutBoxText'> </p>
        <p className='aboutBoxText'></p>
          
          <p className='aboutBoxText'> <b> </b> </p>
          <p className='aboutBoxText'></p>
          <p className='aboutBoxText'></p>
        </div>
    </div>
  </div>
</section>
<div className='infoContainer'></div>
    </div>
  )
}

export default NotesPage