import '../scss/privacy.scss';

function TermPage() {

  return (
    <div  className="body-inner" id="termcondition">
      <section id="main-container" className="infoContainer">
  <div className="container privacySecBox">
    <div className="row">
        <div className="col-lg-12 who-we-are">
          <h4 className="secTitle">PLEASE READ THESE TERMS AND CONDITIONS OF SERVICE BEFORE USING RAMESWARAM DHIYA TOURS AND TRAVELS</h4>
          <p className='boxText'>By using this site, you convey your agreement with these Terms & Conditions and the Privacy Statement. If you do not agree with the terms stated in this agreement or are dissatisfied with the site please direct your complaints to Contact Us page. Failure to comply with these Terms & Conditions may result in legal action and restriction of your ability to access this site. From time to time, Rameswaram Dhiya may modify these Terms & Conditions. Please continue to review this agreement whenever accessing or using this site.</p>
          <p className='boxText'><b>Booking your Holiday.</b> 1. Your contract in respect of your holiday is made with Dhiya Travel & Tourism (‘the Company‘), registered in Oman and all bookings are subject to these terms and conditions.</p>
          <p className='boxText'>2. All communications by the Company in relation to your holiday will be sent to the address stated on the booking form.</p>
          <p className='boxText'>3. All bookings must be made through an authorised representative of the Company. At the time of booking the Company booking form must be completed and submitted together with a deposit of 50% of the total cost of the booking.</p>
          <p className='boxText'>4. Receipt of the deposit and booking form by the Company does neither guarantee nor imply confirmation of the booking. No booking shall be confirmed until the Company issues a written notice. The Company reserves the right to refuse a booking without giving any reason and shall in that event return any deposit received.</p>
          <p className='boxText'>5. Your holiday must be paid in full at least 30 days before the departure date. If payment is not received by the due date, the Company reserves the right to cancel the booking and retain the deposit. The person who signs the booking form guarantees payment of the total amount shown on the booking form in accordance with these conditions. It is the responsibility of the signatory to ensure the Company receives payment in full by the due date. No reminders will be sent.</p>
          <p className='boxText'>6. If you book within 30 days of your departure date payment must be made in full at the time of booking.</p>
          <p className='boxText'>7. All special requests, such as dietary requirements, should be noted on the booking form.</p>
          <p className='boxText'>8. The Company will provide the service as set out and confirmed in writing.</p>
          
          <p className='boxText'><b>Price Policy.</b> 1. The Company is under no obligation to furnish a breakdown of the costs involved in a holiday.</p>
          <p className='boxText'>2. The Company reserves the right to notify you of any increase in price before accepting your booking.</p>
          <p className='boxText'>3. After a Confirmation Invoice has been issued, unless you choose to pay for your holiday in full at the time of booking, the price of your holiday is subject to the possibilities of surcharges in certain limited circumstances. However, a surcharge will only be levied to allow for variations in transportation cost, including cost of fuel, increase in normal published airfares, taxes or fees chargeable for services such as landing taxes at airports, the exchange rate applied to the particular package or if the government or regulatory body introduce an increase of taxes. Even then, the Company will absorb an amount equal to 2% of your holiday price (excluding insurance premiums and amendment charges) before passing on any surcharge to you. Only amounts in excess of this 2% will be surcharged. Surcharges will be notified by a revised Confirmation Invoice sent to you.</p>
          <p className='boxText'>4. If a surcharge would increase the total holiday price shown on your original Confirmation Invoice by 10% or more, you may cancel your booking within 14 days of the date of issue of the revised Invoice and obtain a full refund of all payments made to the Company and any amendment charges previously incurred.</p>
          <p className='boxText'>5. Optionally, you may choose to pay for your holiday in full at the time of booking, in which case your holiday price will be fixed at the cost quoted by the Company at the time. To qualify for this benefit you should return the Confirmation Invoice to the Company with full payment to reach the Company within 7 days of the date printed on the Confirmation Invoice.</p>
          <p className='boxText'>6. The financial commitments offered above by the Company mean that the Company is not able to reduce the holiday prices should the value of the Omani Rial strengthen.</p>
        
          <p className='boxText'><b>Cancellation and Changes by the Company.</b> 1. Occasionally it may be deemed necessary to make changes to your holiday and the Company reserves the right to do so at any time, and you will be notified of any changes at the earliest possible opportunity. If a major change to your tour is necessary, providing it does not arise from circumstances beyond the Company’s control, you may choose (i) to accept the change of arrangements (ii) to purchase another holiday from us or (iii) to cancel your holiday. Compensation may be payable in cases of major change as detailed below.</p>
          <p className='boxText'>2. No compensation will be payable for minor changes. Minor changes include minimal changes to departure and arrival times, changes to the type of aircraft used and restaurant and accommodation changes to a comparable or superior standard.</p>
          <p className='boxText'>3. Major changes include cancellation, changes to your UK airport, delays in departure or return by more than 12 hours and accommodation changes to an inferior standard of accommodation.</p>
          <p className='boxText'>4. Compensation will not be paid for changes or cancellations caused by Acts of God (Force Majeure), war, threat of war, riot, civil strike, industrial dispute, terrorist activity, natural or man-made disaster, fire, technical problems to transport, closure or congestion of airports, strikes or other industrial action, adverse weather conditions or any other event beyond the Company’s control. It is essential that you take out adequate travel insurance.</p>
          <p className='boxText'>5. The Company reserve the right to cancel your holiday at any time before the date of departure, even after a confirmation notice has been sent. If your holiday is cancelled the Company will refund in full the money you have already paid. No compensation will be payable.</p>
          <p className='boxText'>6. Transportation is subject to the conditions of carriage of the carrier, some of which may limit or exclude liability. These conditions are often the subject of international agreement between countries and copies of the conditions, which apply to your holiday journey, will normally be found on the carrier’s tickets.</p>
          <p className='boxText'>7. If you fail to pay the balance of the holiday price at least 30 days before departure, the Company will treat the booking as cancelled and levy the cancellation charges set out below.</p>
          <p className='boxText'>8. The company also has the right to refuse any person as a member of the tour, if in their opinion that person could endanger the health, safety and enjoyment of others on the tour. In any of the events mentioned above, the company’s sole liability and the client’s sole remedy will be limited to a refund of any monies paid, less the amount for services already utilised plus administrative fees.</p>
        
          <p className='boxText'><b>Cancellation and Changes by You.</b> 1.You may cancel your holiday at any time providing you notify the Company in writing. The following charges will be levied on any cancellation:</p>
           <p> <li>30 days or more 50% of Deposit</li>
           <li>15 – 30 days 100% of Deposit</li>
           <li>8 – 15 days 80% of Tour Cost</li>
           <li>1 – 7 days 100% of Tour Cost</li>
            </p>
           <p className='boxText'>2. After the itinerary is confirmed, the client is permitted free of administration charges one set of amendments, though will be liable for any added cost this may add to the tour, including airline charges, hotels etc. Any further modifications, which are instigated by the client, will incur a charge of 25 Omani Rials due to communication and administration expenses that may have incurred.</p>

          <p className='boxText'><b>Smoking.</b> The Company forbids smoking in any vehicle operated by the Company. Frequent stops can be arranged for those who desire to smoke. All accommodation will be reserved with non-smoking rooms, unless the client specifically requires a smoking room. Confirmation is depending on hotel availability.</p>
          <p className='boxText'><b>Waiver.</b> The Company will undertake to ensure the safety of the client throughout the tour, however aspects of each particular tour may not be without an element of danger. Clients should be prepared to sign a ‘Waiver of Responsibility’ form before they begin their tour. This form is an understanding that all clients need to exercise judgement and care at all times to ensure their own safety and that of their fellow tourists.</p>
          <p className='boxText'><b>Disclaimer.</b> The Company has made all reasonable efforts to check the accuracy of the information contained in our website. The Company cannot however accept any responsibility for any errors or omissions that may appear in this site.</p>
          <p className='boxText'><b>Complaints.</b>1. If you have a problem during your holiday, please inform the relevant airline, hotel, local agent of other suppliers immediately so that they can endeavour to put things right. If you cannot resolve the problems, you must contact the Company office so that they are given an opportunity to help. The Company will not hold themselves responsible for the non-performance of an itinerary through causes beyond their control or when they are not notified of a problem at the point where remedial action can be taken. In the unlikely event that a complaint cannot be resolved at the time, your should write to the Company within 28 days of returning home, giving your original booking reference number and all other relevant information. If you fail to take any of these steps you will hinder the Company’s ability to put any problem right and/or investigate it fully and any right you have to receive compensation will be reduced or completely invalidated.</p>
          <p className='boxText'>2. Any dispute that may arise will be governed by Omani law and both parties shall submit to the jurisdiction of the Omani Courts.</p>
          <p className='boxText'>3. All information given by the Company whether in writing or orally is to the best of the Company’s knowledge and believed correct at the time given and is given in good faith. The booking conditions shall take precedence over any other warranty or condition that may have been given.</p>
          
          <p className='boxText'><b>Data Protection Act.</b>It may be necessary for the Company to ask you for certain personal information. Examples of this would be dietary requirements, disability/medical or religious information etc. This information will be kept confidential by the Company and is available to you to inspect during the Company’s normal working hours. It will be passed to the suppliers, if it is necessary for them to know this information in order to fulfill the Company’s contract to you.</p>
        </div>
    </div>
  </div>
</section>
<div className='infoContainer'></div>
    </div>
  )
}

export default TermPage