import '../scss/privacy.scss';

function PrivacyPage() {

  return (
    <div  className="body-inner"  id="privacy" >
      <section id="main-container" className="infoContainer">
  <div className="container privacySecBox">
    <div className="row">
        <div className="col-lg-12 who-we-are">
          <h4 className="secTitle">PRIVACY STATEMENT</h4>
          <p className='boxText'>Travel agents may have privacy policies that state they will only process personal information for legal reasons, such as to manage bookings, fulfill travel arrangements, or comply with legal obligations. They may also state that they will only use personal information to improve products and services, personalize offers, develop new products, or prevent fraud. </p>
          <p className='boxText'><b>Journey Travel & Tours.</b> Will safeguard customer information according to strict standards of security and confidentiality, and limit the collection and use of customer information to the minimum required to deliver superior service..</p>
          <p className='boxText'><b>Travel Talk Tours.</b>Will not sell, rent, or trade personal information, and will only disclose it to third parties in the ways set out in the policy, and in accordance with local data protection laws.</p>
          <p className='boxText'><b>Travel Booking Agent.</b>Will allow customers to access all personally identifiable information online, and will take reasonable steps to verify identity before granting access or making corrections.</p>
        </div>
    </div>
  </div>
</section>
<div className='infoContainer'></div>
    </div>
  )
}

export default PrivacyPage
