import '../scss/home.scss';
import styled from "styled-components";
import { imageZoomEffect, TitleStyles } from "../services/ReusableStyle";
import {  mostVisitedPackages, myBlogs } from '../services/impImages';
import { Tooltip } from '@mui/material';
import { Link } from 'react-router-dom';
import { ToastContainer } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';
import 'slick-carousel/slick/slick.css';
import 'slick-carousel/slick/slick-theme.css';
import Slider from 'react-slick';
import { useNavigate } from 'react-router-dom';
import{FaPhoneAlt } from 'react-icons/fa';

function HomePage() {
  let navigate=useNavigate();
  const handleClick = (e) => navigate('/#');//toast("Currently Myvisapriority.com doesn't provide online visa application to " + e.target.name + ", Kindly call us to get receive more information from our Visa Specialists, Please contact us on +971 55 636 8548");

  const slides = [
    {id:1, url: 'assets/slider-main/1.jpg' }
  ];

  const testimonials = [
    {
      id:1,
      name: "- Dr.Rajiv Ramnath",
      content:"Different travel service and prompt pick up and drop with safe operations. Great to see some people still like this..The various safari's provided all types of experiences, from very upscale camps to more primitive bush camps all expertly chosen for very specific reasons."
    },
    {
      id:2,
      name: "- Dr.Cindrella",
      content:"What a brilliant travel agency and drivers. Heart touching service and very attractive offers. we wish them !!!We would definitely use you again for future travel plans, in fact, you are currently planning our trip to India."
    },
    {
      id:3,
      name: "- Dr.John Joel",
      content:"Thank you for the fantastic trip to South Rameswaram Madurai, that you and your team planned for us. This was a trip that had not been high on our bucket list but close friends talked us into going with them. This trip turned out to be beyond our expectations all customized just for the four of us!! The planning details were perfect and the accommodations were superb."
    },
    {
      id:4,
      name: " - DR.Mega",
      content:
        "I just realized that we have been back from Rameswaram for 6 weeks now. I cannot believe we have been back that long. I am embarrassed that I took this long to get in touch with you. That said, I just wanted to tell you that we had an absolutely fantastic time on our trip. I can attest without hesitation the primary reason we had just had a great time was due to you and the care and attention you put into planning our trip."
    }
  ];

  let mainSettings = {
    infinite: true,
    autoplay:true,
    speed: 1000,
    arrows: false,
    slidesToShow: 1,
    slidesToScroll: 1,

    responsive: [
      {
        breakpoint: 960,
        mainSettings: {
          slidesToShow: 1,
          slidesToScroll: 1,
          arrows: false,
        },
      },
      {
        breakpoint: 480,
        mainSettings: {
          slidesToShow: 1,
          slidesToScroll: 1,
          arrows: false,
        },
      },
    ],
  };

  let settings = {
    infinite: true,
    autoplay:true,
    speed: 1000,
    arrows: true,
    slidesToShow: 3,
    slidesToScroll: 2,

    responsive: [
      {
        breakpoint: 960,
        settings: {
          slidesToShow: 3,
          slidesToScroll: 2,
          arrows: false,
        },
      },
      {
        breakpoint: 480,
        settings: {
          slidesToShow: 1,
          slidesToScroll: 1,
          arrows: false,
        },
      },
    ],
  };

  let testimonialSettings = {
    infinite: true,
    autoplay:true,
    speed: 1000,
    arrows: true,
    slidesToShow: 2,
    slidesToScroll: 1,

    responsive: [
      {
        breakpoint: 960,
        settings: {
          slidesToShow: 1,
          slidesToScroll: 1,
          arrows: false,
        },
      },
      {
        breakpoint: 480,
        settings: {
          slidesToShow: 1,
          slidesToScroll: 1,
          arrows: false,
        },
      },
    ],
  };

  
  return (
    <div className="body-inner" id="home">
      <section className="banner-carousel banner-carousel-1 mb-0">
        <div className='topHead'>
      
      </div>
        <ToastContainer style={{ width: '100%' }} />
        <div className='containerStyles'>
        <Slider {...mainSettings}>
        {slides.map((image) => (
                        <img loading="lazy" key={image.id} className='imgContainer' src={image.url} alt='Ratchu Software' />
                ))}
        </Slider>
        </div>
      </section>

      <div className="gap-40"></div>
  
    
<Section id="main-container" className="infoContainer">
  <div className="container aboutSecBox">
  <div className="row text-center">
        <div className="col-lg-12">
          
        </div>
    </div>
    <div className="row">
        <div className="col-lg-8">
        <h2 className="secMidTitle"> Hi, I'm <span className="text-designColor capitalize">Sruti Harihara Subramanian</span></h2>
          <p className='aboutBoxText'>Sruti Harihara Subramanian is a <i>National Award-winning</i> filmmaker and storyteller who has
                been immersed in the practice of her craft. Her journey as a filmmaker began as a director for
                the critically acclaimed, award-winning film<i> A Far Afternoon – A Painted Saga By Krishen
                  Khanna (2015),</i> which garnered her two National Awards: in for the best Art/Culture film and
                for the best music in the non-feature category. She also conceptualised a docuseries to direct
                the <i>Grammy and Oscar-winning musician A. R. Rahman in his first ever web-series, Harmony
                  with A. R. Rahman (2018).</i> </p>
        </div>
        <div className="col-lg-4 mt-5 mt-lg-0">
          <div  className="aboutImg">
          <img src="./assets/slider-pages/1.jpg" alt="image" width="100%"/>
          </div>    
        </div>
    </div>
  </div>
</Section>



      
      
  
    </div>
  )
}

export default HomePage;

const Section = styled.section`
  ${TitleStyles};
  ${imageZoomEffect};
  `;