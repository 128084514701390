import React from 'react'
import { bankDetails, bankName } from '../services/auth';
import '../scss/privacy.scss';

function BankPayment() {
  return (
    <div  className="body-inner"  id="privacy" >
    <section id="main-container" className="infoContainer">
<div className="container privacySecBox">
  <div className="row">
      <div className="col-lg-12 who-we-are">
        <h4 className="secTitle">PAYMENT OPTIONS</h4>
        <div className='table-responsive'>
        <table className='bankTable table-bordered'>
        <thead className='tableHead'>
        {bankName.map((title) => (
          <tr key={title.id}>
             <th>
           {title.label}
             </th>
              <th>
              {title.name}
                </th>
          </tr>
           ))}
          </thead>
          <tbody className='tableBody'>
          {bankDetails.map((details) => (
          <tr key={details.id}>
             <td>
           {details.label}
             </td>
              <td>
              {details.name}
                </td>
          </tr>
           ))}
          </tbody>
        </table>
        </div>
        </div>
    </div>
  </div>
</section>
<div className='infoContainer'></div>
    </div>
  )
}

export default BankPayment