import '../scss/notfound.scss';

function NotFoundPage() {
 // const current = new Date();
  //const edate = `${current.getDate()}/${current.getMonth()}/${current.getFullYear()}`;
  //const date = `${current.getDate()}`;
 // const month = `${current.getMonth()}`;
  //const year = `${current.getFullYear()}`;
  return (
    <h2 className='notfound' id="notfound">Maintenance Payment Not Confirm So Page Not found</h2>
  )
}

export default NotFoundPage