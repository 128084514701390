import '../scss/navbar.scss';
import { Link } from 'react-router-dom';
import{FaBars,FaTimes,FaEnvelope, FaPhoneAlt } from 'react-icons/fa';
import { CiBank } from "react-icons/ci";
import { useState } from 'react';

function NavBar() {
  const [icon,SetIcon]=useState(false);

  const handleClick=()=>{
    SetIcon(!icon);
   };

   const closeSideDrawer=()=>{
    SetIcon(false);
   };

  return (
    <>
    <div className='topper'>
  <ul className='infoBox'>
 
    <li>
      <a href="#"  className='infoText'>
      <img src="./assets/icon-image/twitter.png" alt="Twitter" className='infoWaIcon'/>
        </a>
    </li>
    <li>
      <a href="#" className='infoText'>
      <img src="./assets/icon-image/linkedin.png" alt="instagram" className='infoWaIcon'/> 
        </a>
    </li>
    <li>    
      <a
          href="#"
          target="_blank"
          rel="noopener noreferrer"  className="infoText"
        >
        <img src="./assets/icon-image/wappicons.png" alt="Rameswaram Dhiya" className='infoWaIcon'/></a>
    </li>
  </ul>
</div>
    <nav className='navBar'>
    <div className="menuIcon" onClick={handleClick}>
      {icon?<FaTimes className='faTimes'/>:<FaBars className='faBars'/>}
    </div>
      <ul className={icon? 'navMenu active' :'navMenu'}>
        <li>
      <Link className="navLink" data-no-turbolink="true" to="/" onClick={closeSideDrawer}>Home</Link>
      </li>
      <li>
      <Link className="navLink" data-no-turbolink="true" to="/notes" onClick={closeSideDrawer}>Director’s note</Link>
      </li>
      <li>
      <Link className="navLink" data-no-turbolink="true" to="/works" onClick={closeSideDrawer}>Works</Link>
      </li>
      <li>
      <Link className="navLink" data-no-turbolink="true" to="/press" onClick={closeSideDrawer}>Press</Link>
      </li>
      <li>
      <Link className="navLink" data-no-turbolink="true" to="/about" onClick={closeSideDrawer}>Company</Link>
      </li>
      <li>
      <Link className="navLink" data-no-turbolink="true" to="/testimonials" onClick={closeSideDrawer}>Awards/Testimonial</Link>
      </li>
      <li>
      <Link className="navLink" data-no-turbolink="true" to="/gallery" onClick={closeSideDrawer}>Gallery</Link>
      </li>
      <li>
      <Link className="navLink" data-no-turbolink="true" to="/contact" onClick={closeSideDrawer}>Contact us</Link>
      </li>
      
      </ul>
    </nav>

    </>
  )
}

export default NavBar;