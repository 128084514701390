import '../scss/about.scss';
import { useParams } from 'react-router-dom';
import { Link } from 'react-router-dom';
import Slider from 'react-slick';
import React, { useState,useEffect  } from "react";
import styled from "styled-components";
import { imageZoomEffect, TitleStyles } from "../services/ReusableStyle";
import parse from 'html-react-parser';
import { blogDetails, mostVisitedPackages, myBlogs } from '../services/impImages';

function DetailsPage({ title }) {
    let { id } =useParams();
    const [data,setData]=useState([]);

    let mainSettings = {
        infinite: true,
        autoplay:true,
        speed: 1000,
        arrows: false,
        slidesToShow: 1,
        slidesToScroll: 1,
    
        responsive: [
          {
            breakpoint: 960,
            settings: {
              slidesToShow: 1,
              slidesToScroll: 1,
              arrows: false,
            },
          },
          {
            breakpoint: 480,
            settings: {
              slidesToShow: 1,
              slidesToScroll: 1,
              arrows: false,
            },
          },
        ],
      };
    
      const mainSlides=[
        {id:1,url:'../assets/slider-main/1.jpg', head:'',title:'',subTitle:''},
      ];
   /* 
    const containerStyles={
    
      margin:'0 auto',
      objectFit: 'cover'
    }

    const handleSearch = (e) => {
     setData(search.filter(data=>data.name.toLowerCase().includes(e.target.value.toLowerCase()))); 
   };
    */
  /*
   const fetchCategories = async(e) => {
    filteredProduct = await mostVisitedPackages.filter((data) => data.id === id);
   };
   
      useEffect(() => {
        fetchCategories();
      }, []);
    
     */

      useEffect(() => {
        const fetchData = async () => {
          try {
            console.log(title);
              if(title==="packages"){
                setData(mostVisitedPackages.filter((data) => data.id === id));
               // setData(mostVisitedPackages.filter(data=>data.id.toLowerCase().includes(id.toLowerCase())));
              }else if(title==="places"){
                setData(myBlogs.filter((data) => data.id === id));
               // setData(blogDetails.filter(data=>data.id.toLowerCase().includes(id.toLowerCase())));
              }else{
                setData(blogDetails.filter((data) => data.id === id)); 
                //setData(myBlogs.filter(data=>data.id.toLowerCase().includes(id.toLowerCase())));
              }
            
          } catch (err) {
            console.log(err);
          }
        }
        fetchData();
      }, [id,title]);
      
  return (
    <div  className="body-inner" id="about">
      <section className="banner-carousel banner-carousel-1 mb-0">
      <div className='aboutHomeText'>
                <h6>{title}</h6>
                <Link to='/' className='homeTextLink'>Home</Link> | {title}
      </div>
       <div className='mainContainerStyles'>
            <Slider {...mainSettings}>
        {mainSlides.map((image) => (
                        <img loading="lazy" key={image.id} className='aboutImgContainer' src={image.url} alt='Ratchu Software' />
                ))}
        </Slider>
          </div>
      </section>

<Section id="main-container" className="infoContainer">
  <div className="container aboutSecBox">
  <div className="row text-center">
        <div className="col-lg-12">
          
        </div>
    </div>
    {data.map((input) => (
    <div className="row">
       <div className="col-lg-6 mt-8 mt-lg-0">
          <div className='aboutImg'>
          <img src={'.'+input.img} alt={input.title} width="100%"/>
          </div>    
        </div>
        <div className="col-lg-6">
        <h2 className="secMidTitle">{input.name}</h2>
          <p className='aboutBoxText'>{parse(input.desc)} </p>
        </div>
       
    </div>
     ))}
  </div>
</Section>

<section className="infoContainer" style={{ backgroundColor: 'darkblue' }}>
        <div className="container">
          <div className="row">
            <div className="col-md-12">
              <div className="subscribe-call-to-acton">
                <h1 className='homeCallUs'> Call Us +91 7010332075</h1>
              </div>
            </div>
          </div>
        </div>
      </section>

    </div>
  )
}

export default DetailsPage;

const Section = styled.section`
  ${TitleStyles};
  ${imageZoomEffect};
  display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    margin-top: 3rem;
    padding-top: 1rem;
    `;